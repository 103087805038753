<template>
  <surround-box>
    <div class="content-box">
      <div
        @click="filterChange(item.siteType)"
        :class="[
          'item',
          filter && filter.has(item.siteType) ? 'selected-type' : '',
        ]"
        v-for="item in data"
        :key="item.name"
      >
        <!-- <img :src="item.servicePath + item.logo" /> -->
        <img :src="`/imgs/map/sitetype${item.siteType}.png`" />
        <span> {{ item.name }}（{{ item.num }}） </span>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox";

export default {
  components: { surroundBox },
  props: {
    filter: Set,
    farmId: String,
  },
  data() {
    return {
      data: [],
    };
  },
  watch: {
    farmId() {
      this.getData();
      this.$emit("clear");
    },
  },
  methods: {
    // 获取数据
    getData() {
      this.$post(this.$api.LEGEND.DEVICE_LEGEND, { farmId: this.farmId }).then(
        (res) => {
          this.data = res;
        }
      );
    },
    // 图例筛选
    filterChange(siteType) {
      this.$emit("filter", siteType);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang='less' scoped>
.content-box {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  color: #fff;
  .item {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;
    img {
      width: 24px;
      margin-right: 8px;
    }
  }
  .selected-type {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>